import {React, useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { Container, Flex, MantineProvider, Text, PinInput, Button, Title, Mark } from '@mantine/core';
import submitForm from '../../services/submitForm';

export default function Login(){
  const [isError, setIsError] = useState(false);
  const [pin, setPin] = useState('');

  const submitPin = ((event) => {
    submitForm('/login', {
      'pin': pin
    }).then(result => {
      console.log(result);
      if(result['status'] == false)
        setIsError(true);
      else
        window.location.href = "/questions"
    });
  });

  return(
    <Flex justify="center" align="center" direction="column" gap="lg" sx={{height: '90vh'}} px="xl">
      <Title order={4} align='center'>6-Digit Pin</Title>
      <Title order={6} align='center' italic>Enter the <Mark>known 6-digit code</Mark> to unlock the message within.</Title>
      <PinInput length={6} type="number" error={isError} value={pin} onChange={(event) => setPin(event)}/>
      <Button variant="white" onClick={submitPin} disabled={pin.length < 6}>
        Unlock
      </Button>
    </Flex>
  );
}

const container = document.getElementById('login');

if(container != null)
  ReactDOM.createRoot(container).render(
    <MantineProvider theme={{ colorScheme: 'dark', fontFamily: 'Nunito, Sans Serif' }} withGlobalStyles withNormalizeCSS>
        <Login />   
    </MantineProvider>
  );