import {React, useState, useEffect, useRef} from 'react';
// import ReactDOM from 'react-dom/client';
import { createStyles } from '@mantine/core';
import { Grid } from '@mantine/core';
import { Title } from '@mantine/core';
import { Text } from '@mantine/core';
import { Stack } from '@mantine/core';
import { Flex } from '@mantine/core';
import { Container } from '@mantine/core';
import { Button } from '@mantine/core';
import { SimpleGrid } from '@mantine/core';
import { Divider } from '@mantine/core';
import { Loader } from '@mantine/core';
import { Skeleton } from '@mantine/core';
import { Avatar } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  serverMessage: {
    maxWidth: '70%',
    backgroundColor: theme.colors.dark[4],
    padding: '.5rem 1rem',
    alignSelf: 'flex-start',
    borderRadius: '15px',
    wordWrap: 'break-word',
    borderBottomLeftRadius: '0'
  },

  serverLoading: {
    backgroundColor: theme.colors.dark[4],
    padding: '1rem',
    alignSelf: 'flex-start',
    borderRadius: '15px',
  },

  clientMessage: {
    maxWidth: '70%',
    backgroundColor: theme.colors.blue[9],
    padding: '.5rem 1rem',
    alignSelf: 'flex-end',
    borderRadius: '15px',
    wordWrap: 'break-word',
    borderBottomRightRadius: '0px',
  },

  messageArea: {
    height: '50vh',
    overflowY: 'auto'
  },

  answerArea: {
    height: '9rem',
    overflowY: 'auto',
    marginTop: '0rem',
    position: 'fixed',
    left: '0px',
    right: '0px',
    width: '100%',
    padding: '0.5rem 2rem',
  },

  fullScreen: {
    height: '100vh',
    width: '100vw',
  }
}));

function getCurrentTime(){
  let today = new Date();
  let time = today.getHours() + ":" + (today.getMinutes()<10?'0':'') + today.getMinutes();
  return time.toString();
}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

function Chat(props){
  const { classes, cx } = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isEnded, setIsEnded] = useState(false);
  const [wordCount, setWordCount] = useState(30);

  //Memastikan halaman selalu ke scroll ke paling bawah setiap ada pesan baru
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  //----------------------

  //onLoad()
  useEffect(() => {
    getQuestion(1);
  }, [])

  const getQuestion = async (id) => {
    setIsLoaded(false);
    await delay(wordCount * 50); //supaya lebih realistis

    fetch("question?id=" + id)
    .then(res => res.json())
    .then(
      (result) => {
        //0. kalkulasi waktu baca
        setWordCount(result['q_desc'].length)
        //1. tambahin message baru ke dalam chat.
        let temp = messages;
        let obj = {
          id: id,
          isServer: true,
          time: getCurrentTime(),
          message: result['q_desc'],
          isTail: result['is_tail']
        };  
        temp.push(obj);
        setMessages(temp);
        setIsLoaded(true);
        if(result['is_tail']) 
          if(result['ans'].length > 0)
            setAnswers(result['ans']);
          else
            setIsEnded(true);
        
      },
      (error) => {
        //TODO : error handling
        setIsLoaded(true);
        setIsEnded(true);
      }
    )
  };

  //buat cek apakah ada pesan berantai dari server?
  useEffect(() => {
    scrollToBottom(); //titipan, ga rapi untuk taro function ini di sini.
    if(!isLoaded) return;
    const lastElement = messages[messages.length - 1];
    if(lastElement['isTail'] == false){
      getQuestion(parseInt(lastElement['id']) + 1);
    }
  }, [isLoaded]);

  const handleClick = (e, idNext, text) => {
    let temp = messages;
    let obj = {
      isServer: false,
      time: getCurrentTime(),
      message: text
    };
    temp.push(obj);
    setMessages(temp);
    getQuestion(idNext);
  };

  let bubbles = messages.map((item, index) => 
    <Flex key={index} direction="column" gap="1px" className={item.isServer? classes.serverMessage : classes.clientMessage}>
      <Text size="sm">{item.message}</Text>
      <Text size="xs" c="dimmed" ta="right">{item.time}</Text>
    </Flex>
  );

  const choices = answers.map((item, index)=>{
    return(
      <Button fullWidth variant='outline' key={index} onClick={(e) => handleClick(e, item['q_next'], item['a_desc'])}>
          <i className={'fa-solid ' + item["a_icon"]}></i>{" " + item['a_desc']}
      </Button>
    )
  });

  return(
    <Container>
      {/* <Grid>
        <Grid.Col>
          <Title order={5} align='center' italic color="dimmed">There's Still Hope, Or So I Thought</Title>
        </Grid.Col>
      </Grid> */}

      <Grid mb="lg" mx="xs" align='flex-end'>
        <Grid.Col span="content">
          <Avatar src={props.avatar} color="green" radius="xl" size="lg" variant="outline"/>
        </Grid.Col>
        <Grid.Col span="auto">
            <Title order={5}>Sheldon L. Cooper</Title>
            <Text color="dimmed" fz="sm" fw="700">{!isLoaded? 'Typing...' : 'Online'}</Text>
        </Grid.Col>
      </Grid>

      <Divider my="sm"/>

      <Container className={classes.messageArea}>
        <Stack>
          <Text color="dimmed" fz="sm" fs="italic" align='center'>This is the beginning of the conversation</Text>

          {bubbles}

          {!isLoaded?
          <Flex direction="column" gap="1px" className={classes.serverLoading}>
            <Loader variant='dots'/>
          </Flex>
          : null}

          {isEnded?
          <Text color="dimmed" fz="sm" fs="italic" align='center'>This is the end of the conversation</Text>
          : null}
        </Stack>

        <Container ref={messagesEndRef}></Container>
      </Container>

      <Divider my="sm" label="Pick your answer here" labelPosition="center"/>
      {isEnded? null : 
      <SimpleGrid cols={1} className={classes.answerArea} spacing="xs" verticalSpacing="xs">
        {!isLoaded?
          <>
          <Skeleton height={30} radius="xl" />
          <Skeleton height={30} radius="xl" />
          <Skeleton height={30} radius="xl" />
          </>
        : choices}
      </SimpleGrid>
      }
    </Container>
  )
}

export default Chat;
