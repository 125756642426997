/**
 * @param {string} url 
 * @param {object} data 
 */
export default async function submitForm(url, data, method='POST'){
  let formData = new FormData();
  for(let key in data){
    formData.append(key, data[key]);
  }
  
  const response = await fetch(url,{
    method: method,
    body: formData,
    headers: {
      'X-CSRF-TOKEN': csrfToken,
    }
  })
  .then(response => response.json())
  .then(data => {
    return data;
  })
  .catch(error => {
    console.log('submitForm : ' + error); //TODO DELETE
    return error;
  });

  return response;

  // [DEBUG] Display the key/value pairs
  // for (var pair of formData.entries()) {
  //   console.log(pair[0]+ ', ' + pair[1]); 
  // }
}