import {React, useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import Chat from '../chat/Chat.jsx';

export default function Questions() {
    return(
        <Chat avatar={document.getElementById('questions').getAttribute('avatar-src')}/>
    );
}

const container = document.getElementById('questions');
if(container != null)
    ReactDOM.createRoot(document.getElementById("questions")).render(
        <MantineProvider theme={{ colorScheme: 'dark', fontFamily: 'Nunito, Sans Serif' }} withGlobalStyles withNormalizeCSS>
            <Questions />   
        </MantineProvider>
    );

//0811 2345 53
//Pak Andhy Badan Geologi, Kementrian ESDM (energi dan sumber mineral)
//Web API (payment gateway untuk tiketing pengunjung online).
//grup (reservasi 50-200 orang). Tujuannya ini untuk grup / pribadi.

//Si manajemen peminjaman ruang rapat. 
